import Vue from 'vue'

import VueRouter from 'vue-router'
import VueMask from 'di-vue-mask'
import VueCookie from 'vue-cookie'
import VueTheMask from 'vue-the-mask'
import VuePaginate from 'vue-paginate'
import VueHotkey from 'v-hotkey'
import money from 'v-money'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueClipboards from 'vue-clipboards'
import Fragment from 'vue-fragment'

import './vee-validate'
import './bootstrap'
import './filters'
import './directives'
import './components'
import store from './stores'
import routers from './routers'
import BootstrapVue from 'bootstrap-vue'
import './prototypes'
import Root from './components/layouts/Root'

Vue.use(Fragment.Plugin)
Vue.use(BootstrapVue)
Vue.use(VueAwesomeSwiper /* { default global options } */)
Vue.use(VueHotkey)
Vue.use(VueCookie)
Vue.use(VueMask)
Vue.use(VueRouter)
Vue.use(VueTheMask)
Vue.use(VueClipboards)
Vue.use(money, {
  precision: 2,
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
})

let router = new VueRouter(routers)

Vue.mixin({
  computed: {
    _() {
      return _
    },
  },
  methods: {
    $(rootObject, nodes, defaultNode) {
      let _defaultNode = defaultNode != undefined ? defaultNode : null
      return _.get(rootObject, nodes, _defaultNode)
    },
    replaceQuery(oQuery) {
      let Query = Object.assign({}, this.$route.query)

      // _.each(oQuery,(q,key) => {
      //     if(typeof Query[key] !== undefined){
      //         Query[key] = q
      //     }
      // })

      let query = {
        ...Query,
        ...oQuery,
      }
      let obj = _.omitBy(query, (m) => {
        return !m
      })

      return this.$router.replace({
        query: obj,
      })
    },
    toNumber(value) {
      return Number(value)
    },
    moment() {
      return moment.apply(this, arguments)
    },
    getRoute() {
      return this.getRouter(this.$route.name)
    },
    getRouter(name) {
      return _.find(this.$root.getAuthRoutes(), (r) => r.name == name)
    },
    focusByCheckbox(e, ref) {
      if (e.target.checked == true) {
        this.$nextTick(() => {
          $(this.$refs[ref]).focus()
        })
      }
    },
    number(value, decimal) {
      var d = !decimal && decimal !== 0 ? 2 : decimal
      if (d == 0) return Number(value).toFixed(0)
      return Number(Number(value).toFixed(d)).toLocaleString('pt-br', {
        minimumFractionDigits: 2,
      })
    },
    documentFormat(documento) {
      documento = _.replace(documento, /[^0-9]/, '')

      let oDoc = {
        formated: documento,
        number: documento,
        type: null,
      }

      let doc = _.get(documento, 'length')

      switch (_.get(documento, 'length')) {
        case 8:
          oDoc.type = 'RG'
          oDoc.formated = _.replace(
            documento,
            /([0-9]{2})([0-9]{3})([0-9]{3})/,
            '$1.$2.$3'
          )
          break
        case 9:
          oDoc.type = 'RG'
          oDoc.formated = _.replace(
            documento,
            /([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{1})/,
            '$1.$2.$3-$4'
          )
          break
        case 11:
        case 10:
          oDoc.type = 'CPF'
          oDoc.formated = _.replace(
            documento,
            /([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{1,2})/,
            '$1.$2.$3-$4'
          )

          break
        case 14:
          oDoc.type = 'CNPJ'
          oDoc.formated = _.replace(
            documento,
            /([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/,
            '$1.$2.$3/$4-$5'
          )

          break
      }

      return oDoc
    },
  },
})

new Vue({
  render: (h) => h(Root),
  router,
  store,
  data() {
    return {
      loading: false,
      cliente: null,
      user: null,
      menu: [],
      conexoesWhatsapp: [],
    }
  },
  computed: {
    whatsappAvaliableConnections() {
      return this.conexoesWhatsapp.filter((c) => c.status === 'conectado')
    },
  },
  watch: {
    user(newValue, oldValue) {
      if (newValue !== oldValue && newValue && this.$router.currentRoute) {
        if (this.$router.currentRoute.name == 'login') {
          this.$router.push('/')
          return
        }
        let route = undefined
        _.each(this.getAuthRoutes(), (r) => {
          let children = undefined
          if (r.children != undefined && r.children.length > 0) {
            children = _.find(r.children, (c) => {
              return c.name == this.$router.currentRoute.name
            })

            if (children != undefined) {
              route = r
            }
          } else if (r.name == this.$router.currentRoute.name) {
            route = r
          }
        })
        let permission = _.find(newValue.grupo.permissoes, (up) => {
          return up.chave == route.name
        })
        let autorizacao = _.find(this.cliente.autorizacoes, (a) => {
          return a.chave == this.$(route, 'permissions.key')
        })

        if (
          this.user.master != 'sim' &&
          route.forceAllow == undefined &&
          route.permissions != undefined
        ) {
          if (
            autorizacao == undefined ||
            (autorizacao != undefined && autorizacao.status !== 'liberado')
          ) {
            if (
              autorizacao != undefined &&
              Number(autorizacao.is_allowed) == 0
            ) {
              swal({
                type: 'info',
                title: 'Aguardando liberação de acesso',
                text: 'Entre em contato com a Doação Solutions, para mais informações',
              })
            }
            this.$router.push({
              name: 'error',
              params: { id: 401 },
              query: { name: route.label, on: '1' },
            })
          }
        }

        if (
          this.user.master != 'sim' &&
          permission == undefined &&
          (route.forceAllow == undefined || route.forceAllow == false)
        ) {
          this.$router.push({
            name: 'error',
            params: { id: 401 },
            query: { name: route.label, on: '2' },
          })
        }
      }
    },
  },
  methods: {
    getlistaAtendimentoByDoador(lista) {
      let defaultParam = {
        id: null,
        email: null,
        telefone: null,
        celular: null,
        cpf: null,
        rg: null,
        data_nascimento: null,
        genero: null,
        end_cep: null,
        end_logradouro: null,
        end_numero: null,
        end_complemento: null,
        end_bairro: null,
        end_cidade: null,
        end_uf: null,
      }

      let l = _.filter(lista, (f) => {
        return (
          !_.isEmpty(f.email) ||
          (!_.isEmpty(f.telefone) && f.telefone.length >= 8) ||
          (!_.isEmpty(f.celular) && f.celular.length >= 8)
        )
      })

      return _.map(l, (m) => {
        if (m.sexo == null) {
          m.sexo = ''
        }
        return {
          id: m.doador_id ? m.doador_id : null,
          nome: m.nome,
          email: m.email,
          telefone: m.telefone,
          celular: m.celular,
          cpf: m.cpf,
          data_nascimento: m.data_nascimento,
          genero: m.sexo && m.sexo.toLowerCase(),
          end_cep: m.endereco ? m.endereco.cep : null,
          end_logradouro: m.endereco ? m.endereco.end : null,
          end_numero: m.endereco ? m.endereco.numero : null,
          end_complemento: m.endereco ? m.endereco.complemento : null,
          end_bairro: m.endereco ? m.endereco.bairro : null,
          end_cidade: m.endereco ? m.endereco.cidade : null,
          end_uf: m.endereco ? m.endereco.uf : null,
        }
      })
    },
    logout() {
      this.$cookie.delete('authsession')
      this.$router.push({ name: 'login' })
    },
    async fetchCliente() {
      this.loading = true
      let cliente = await this.$http.get('cliente')

      if (cliente && cliente.status == 200) {
        this.cliente = cliente.data
        let usuario = await this.fetchUser()

        this.loading = false
        if (
          _.get(usuario.grupo.unidade, 'id', null) &&
          usuario.master != 'sim'
        ) {
          this.cliente.unidades = [usuario.grupo.unidade]
        }
      } else {
        this.loading = false
      }
    },
    async fetchUser() {
      this.fetchWhatsappConnections()
      let usuario = await this.$http.get('usuarios/autenticado')
      if (usuario.status == 200) {
        this.user = usuario.data
        this.menu = this.fetchMenu()
      }
      return this.user
    },
    async getParametro(chave) {
      let parametro = await this.$http.get(`parametros/${chave}`)

      return parametro.data
    },
    fetchMenu() {
      return _.filter(this.getAuthRoutes(), (item) => {
        return (
          item.parent == undefined &&
          (item.menu == undefined || item.menu == true)
        )
      })
    },
    checkPermissionItemMenu(item) {
      if (!this.user) return false
      if (item && this.user.master == 'sim') return true

      return (
        _.find(this.user.grupo.permissoes, (permission) => {
          return permission.chave == item.name
        }) != undefined
      )
    },
    checkPermissionByKey(key) {
      if (typeof key == 'string') key = { name: key }

      if (!this.user) return false

      if (key && this.user.master == 'sim') return true

      let avaliables = this.permissionsAvaliables()
      let keyContainer

      if (key.path != undefined) {
        let r = this.$router.resolve(key.path)
        key.name = r.route.name
      }

      /*    _.each(avaliables, (a) => {
                    let children = undefined
                    if (a.children != undefined && a.children.length > 0) {
                        children = _.find(a.children, c => {
                            return c.path == key.path
                        })

                        if (children != undefined) {
                            keyContainer = a
                        }
                    } else if (a.path == key.path) {
                        keyContainer = a
                    }
                })
            } else */

      if (key.name != undefined) {
        _.each(avaliables, (a) => {
          let children = undefined
          if (a.children != undefined && a.children.length > 0) {
            children = _.find(a.children, (c) => {
              return c.name == key.name
            })

            if (children != undefined) {
              keyContainer = a
            }
          } else if (a.name == key.name) {
            keyContainer = a
          }
        })
      } else {
        return false
      }

      let allow = _.find(this.user.grupo.permissoes, (p) => {
        return keyContainer != undefined && p.chave == keyContainer.name
      })

      if (allow == undefined) return false

      if (keyContainer.permissions != undefined) {
        let autorizacao = _.find(this.cliente.autorizacoes, (a) => {
          return a.chave == keyContainer.permissions.key
        })
        if (
          autorizacao == undefined ||
          (autorizacao != undefined && autorizacao.status !== 'liberado')
        ) {
          return false
        }
      }

      return true
    },
    permissionsAvaliables() {
      return _.filter(this.getAuthRoutes(), (r) => {
        return (
          r.component != undefined &&
          r.description != undefined &&
          (r.forceAllow == undefined || r.forceAllow == false)
        )
      })
    },
    getAuthRoutes() {
      return _.find(routers.routes, (r) => {
        return r.auth != undefined
      }).children
    },
    getRotasByParentName(parent_name) {
      return _.filter(
        this.getAuthRoutes(),
        (r) => r.parent == parent_name && !r.forceAllow
      )
    },
    fetchWhatsappConnections() {
      if (!this.$cookie.get('authsession')) return
      this.$core.get('v2/notification/whatsapps/connections').then((event) => {
        if (event && event.status == 200) {
          const itens = event.data
          this.conexoesWhatsapp = itens
        }
      })
    },
  },
  created() {
    const context = this
    if (this.$cookie.get('authsession')) {
      this.$http.defaults.headers.common['Authorization'] =
        'Bearer ' + context.$cookie.get('authsession')

      this.$core.interceptors.request.use((request) => {
        const token = context.$cookie.get('authsession')
        if (token) {
          request.headers.Authorization = `Bearer ${token}`
        }
        return request
      })
    }

    this.$http.interceptors.response.use(
      (response) => {
        if (response.status === 401) {
          context.logout()
        }
        return response
      },
      (error) => {
        if (error.response.status === 401) {
          context.logout()
        }
        return error
      }
    )
  },
}).$mount('#app')
